import jsonFromDocument from "../JsonFromDocument";

const CsrfTokenMiddleware = () => {
  const csrfToken = jsonFromDocument("csrf_token") || "CSRF-TOKEN-NOT-FOUND";
  return (
    <input
      type="hidden"
      id="csrfmiddlewaretoken"
      name="csrfmiddlewaretoken"
      value={csrfToken}
    />
  );
};

export default CsrfTokenMiddleware;
