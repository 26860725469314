import { useState, useCallback } from "react";

function useFormData<T extends {}>(initialFormData: T = {} as T) {
  const [formData, setFormData] = useState(initialFormData);
  const onChange = useCallback((update: any) => {
    setFormData((prev) => ({ ...prev, ...update }));
  }, []);
  return { formData, setFormData, onChange };
}

export default useFormData;
